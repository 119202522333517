import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/lors_logo_olive.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { HashLink } from 'react-router-hash-link';
import TrackVisibility from 'react-on-screen';
import 'animate.css';
import Contact from "./Contact.js";


export const About = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Beautiful.", "Stunning.", "Magnificent.", "Gorgeous.", "Breathtaking.", "Serene.", "Captivating.", "Mesmerizing.", "Enchanting.", "Majestic.", "Radiant.", "Picturesque.", "Impressive.", "Striking.", "Exquisite.", "Inspiring.", "Peaceful.", "Tranquil.", "Harmonious.", "Delightful."];
  const period = 1000;
  
  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(100);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <>
    <section className="about snapScrolling" id="about" style={{flexDirection: 'column'}}>
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>{`Hello, and welcome.`}</h2>
                <p>{`We're volunteers of the,`}</p>
                <h1>Lakes Outdoor Recreation Society</h1>
               
                {/* <h3><span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Tony", "Turntable" ]'><span className="wrap">{text}</span></span></h3> */}
                  <p>Nestled in the heart of Northern British Columbia lies the breathtaking Lakes District, a haven for outdoor enthusiasts looking for a nature getaway. Endless lakes, surrounded by majestic mountains and lush forests that paint a picturesque backdrop inspiring awe and tranquility. Here, anglers are invited to cast their lines and embark on a fishing adventure to remember. As the sun sets, campers revel in the freedom of the wilderness, pitching tents under the starlit sky, breathing in the crisp mountain air, and immersing themselves in nature. The Lakes District of Northern British Columbia offers a harmonious blend of captivating scenery, world-class fishing, and the liberating joy of free camping, making it a true haven for those seeking solace in the great outdoors.</p>
                  <HashLink smooth to="/About#contactMe">
                  <button>Connect <ArrowRightCircle size={25} /></button>
                </HashLink>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
    <section className="contactMe about snapScrolling" id="contactMe">
      <Contact/>
    </section>
    </>
  )
}